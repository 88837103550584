<template>
  <main>
    <package-header title="Package Pending" sub-title="12764 Smith" :showValidate="true" status="Pending"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-2">
          <!-- Package navigation hamburger -->
          <button class="btn btn-secondary d-lg-none mb-3" data-toggle="collapse" data-target="#package-nav" aria-expanded="false" aria-controls="package-nav">
            <svg class="icon icon-menu"><use xlink:href="/icons/symbol-defs.svg#icon-menu"></use></svg>
            <svg class="icon icon-close"><use xlink:href="/icons/symbol-defs.svg#icon-close"></use></svg>
            Navigate Package
          </button>

          <div class="collapse collapse-lg" id="package-nav">
            <package-navigation></package-navigation>
          </div>
        </div>

        <div class="col">
          <div class="alert alert-info" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-info"><use xlink:href="/icons/symbol-defs.svg#icon-info"></use></svg>
            </div>
            <div>
              <strong>How to complete a correction</strong><br />
              To correct an application, complete a corrective declaration on a Form Declaration. Alternatively, you can create a corrected application, and then download and attach a copy of it in support of the corrective Form Declaration. Upload of a Corrective Declaration continue to be done of
              the View Package page in EFS.
            </div>
          </div>

          <div class="alert alert-warning" role="alert">
            <div class="alert-icon">
              <svg class="icon icon-info"><use xlink:href="/icons/symbol-defs.svg#icon-warning"></use></svg>
            </div>
            You have an unsubmitted withdrawal request. Complete and submit the request to ensure the withdrawal is processed.
          </div>

          <div class="d-flex justify-content-between align-items-end pb-2">
            <!-- Table toolbar -->
            <div>
              <div class="btn-toolbar sticky-top" v-show="checked > 0">
                <div class="btn-group">
                  <svg class="icon icon-corner-left-down"><use xlink:href="/icons/symbol-defs.svg#icon-corner-left-down"></use></svg>
                </div>

                <div class="btn-group" role="group">
                  <button type="button" class="btn btn-md btn-secondary">Download</button>
                  <button type="button" class="btn btn-md btn-secondary">Print</button>
                  <button type="button" class="btn btn-md btn-danger">Delete</button>
                </div>
              </div>
            </div>

            <div>
              <router-link class="btn btn-md px-3 btn-secondary" to="/pages/withdraw">+ Withdraw Application</router-link>
              <router-link class="btn ml-1 btn-md px-3 btn-tertiary" to="/pages/add-applications">+ Add Application</router-link>
            </div>
          </div>

          <!-- Package Management table -->
          <div class="sticky-table">
            <table class="table table-hover table-stacked table-select-row">
              <thead class="bg-medium">
                <tr>
                  <th scope="row">
                    <input type="checkbox" id="th" />
                  </th>
                  <th scope="col">Name</th>
                  <th scope="col">Type</th>
                  <th scope="col">Document #</th>
                  <th scope="col">Status</th>
                  <th scope="col">Modified</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableItems" :key="index" :class="index === 2 && 'alert-warning font-italic'">
                  <td scope="row">
                    <input type="checkbox" :id="index" @click="checkbox($event)" />
                  </td>
                  <td data-header="Name">
                    <span class="td-content">
                      <a href="#">{{ item.b }}</a>
                    </span>
                  </td>
                  <td data-header="Application">
                    <span class="td-content">{{ item.c }}</span>
                  </td>
                  <td data-header="Modified">
                    <span class="td-content">{{ item.d }}</span>
                  </td>
                  <td data-header="Status" v-html="item.e"></td>
                  <td data-header="Status" v-html="item.f"></td>
                  <td data-header="Actions" class="text-md-right">
                    <div class="btn-group">
                      <router-link class="btn btn-secondary btn-sm" to="/pages/electronic-instrument"> Actions </router-link>
                      <button type="button" class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void(0)">View form</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Table help -->
          <button class="btn collapse-btn collapsed" data-toggle="collapse" data-target="#application-help" aria-expanded="false" aria-controls="application-help">
            <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
            How to use this table
          </button>
          <div id="application-help" class="collapse" aria-labelledby="headingOne" data-parent="#application-help">
            <div class="collapse-body">Collapse content</div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Package Management"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../components/PackageHeader.vue";
import PackageNavigation from "../../components/PackageNavigation.vue";
import HelpPanel from "../../components/HelpPanel.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel,
  },

  data() {
    return {
      checked: 0,
      sequenced: false,
      tableItems: [
        {
          a: "1",
          b: "Form A",
          c: "Form A (Freehold Transfer)",
          d: "TX6074982",
          e: "Pending",
          f: "Jul 10, 2024",
        },
        {
          a: "2",
          b: "Form B",
          c: "Form B (Mortgage)",
          d: "TX6074983",
          e: "Pending",
          f: "Jul 10, 2024",
        },
        {
          a: "3",
          b: "Withdrawal-ws-test-02",
          c: "Withdrawal Request",
          d: "WR10239",
          e: "Pending",
          f: "Jul 19, 2024",
        },
      ],
    };
  },
  methods: {
    checkbox: function ($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked++;
      } else {
        _this.checked--;
      }
    },
  },
};
</script>
